import * as React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoneyBillWave,
  faUserShield,
  faCheck
} from "@fortawesome/free-solid-svg-icons";

import {
  Jumbotron,
  Row,
  Col,
  Container
} from "react-bootstrap";

import "./ValueProps.css";

export class ValueProps extends React.Component {
  render() {
    return (
        <Container>
          <Jumbotron style={{ padding: '2rem 1rem' }}>
            <h2>Create and download bulk QR codes</h2>
            <p className="lead">Free and simple way to generate QR codes</p>
          </Jumbotron>
          <Row>
            <Col sm>
              <h4>
                <FontAwesomeIcon icon={faMoneyBillWave} /> Free!
              </h4>
              <p>Zero cost, unlimited codes, unlimited scans, no expiry</p>
            </Col>
            <Col sm>
              <h4>
                <FontAwesomeIcon icon={faCheck} /> Easy to use
              </h4>
              <p>
                Create your CSV file in <a href="/Example Excel file for QRCodeHero.xlsx">Excel</a> or <a href="https://docs.google.com/spreadsheets/d/180bRdKTPJU3XWTXHZ0vUw3aPnpWRxVFR7PoB2aVLqbQ/edit?usp=sharing"> Google Sheets </a> and upload here
              </p>
            </Col>
            <Col sm>
              <h4>
                <FontAwesomeIcon icon={faUserShield} /> Privacy
              </h4>
              <p>
                We don't store your data or ask you to connect your personal
                accounts.
              </p>
            </Col>
          </Row>
      </Container>
    );
  }
}
