import * as React from 'react';
import { Color, ColorResult, SketchPicker } from 'react-color';

interface QRCColorPickerProps {
  onChange(color: Color) : void
}

export class QRCColorPicker extends React.Component <QRCColorPickerProps> {
  state = {
    displayColorPicker: false,
    color: '#000',
    label: ''
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  handleChange = (color : ColorResult, event : any) => {
    this.setState({ color: color.hex },  () => {
        this.props.onChange(this.state.color);
    });
    console.log(color);
  };

  render() {
    return (
      <div>
          <SketchPicker color={ this.state.color } onChange={ this.handleChange } />
      </div>
    )
  }
}