import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import GAListener from "./wares/GAListener";

export default function Routes() {
  return (
    <GAListener trackingId="UA-43827174-4">
      <Switch>
          <Route path="/home" component={Home} />
          <Route path="/" component={Home} />
          <Route component={NotFound} />
      </Switch>
    </GAListener>
    );
  }
  