import React from "react";
import QRCode from "qrcode";
import JSZip from "jszip";
import FileSaver from "file-saver";
import Papa from "papaparse";

import ReactGA from 'react-ga';
import {
  Button,
  Form,
  FormCheck,
  Container,
  Row,
  Col
} from "react-bootstrap";

import { ValueProps } from "../components/ValueProps";
import { Footer } from "../components/Footer";
import "./Home.css";
import { QRCColorPicker } from "../components/QRCColorPicker";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      darkColor: "#000",
      lightColor: "#fff",
      addLabelToCode: true
    };
    this.inputFile = React.createRef();
    this.sampleLabel = "QRCodeHero";
    this.sampleData = "https://qrcodehero.com";
  }

  generatePreview(canvas, previewData, previewLabel) {
    const darkColor = this.state.darkColor;
    const lightColor = this.state.lightColor;
    const addLabelToCode = this.state.addLabelToCode;
    // generate preview
    QRCode.toDataURL(
     previewData,
      {
        errorCorrectionLevel: "H",
        quality: 0.9,
        color: {
          dark: darkColor,
          light: lightColor
        }
      }, function(err, qrCodeDataUrl) {
        if (err) {
          throw err;
        }
        var context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);
        var qrCodeImage = new Image();
        qrCodeImage.src = qrCodeDataUrl;
        qrCodeImage.onload = function() {
          context.drawImage(qrCodeImage, 0, 0);
        };
        // write the text on the canvas
        if (addLabelToCode) {
          context.font = "12px Arial"
          context.fillStyle = darkColor;
          context.textAlign = "center";
          context.fillText(previewLabel, canvas.width/2 - 8, canvas.height - 4);
        }
      });
    }

  componentDidMount() {
    ReactGA.initialize('UA-43827174-4');
    this.generatePreview(this.refs.previewCanvas, this.sampleData, this.sampleLabel);
  }

  handleAddLabelToCodeChange = (event) => {
    var checked = event.target.checked;
    this.setState({ addLabelToCode: checked }, function() {
      this.generatePreview(this.refs.previewCanvas, this.sampleData, this.sampleLabel)
    });
    ReactGA.event({
      category: 'Editing',
      action: 'Add label to code',
      label: checked ? "checked" : "unchecked"
    });
  }

  handleLightColorChange = (color) => {
    this.setState({ lightColor: color.hex }, function() {
      this.generatePreview(this.refs.previewCanvas, this.sampleData, this.sampleLabel);
    });
    ReactGA.event({
        category: 'Editing',
        action: 'Selected background color'
    });
  }

  handleDarkColorChange = (color) => {
    this.setState({ darkColor: color.hex }, function() {
      this.generatePreview(this.refs.previewCanvas, this.sampleData, this.sampleLabel);
    });
    ReactGA.event({
      category: 'Editing',
      action: 'Selected foreground color'
    });
  };

  handleFileSelect = event => {
    //console.debug(event.target.files);
    //var file = event.target.files[0];
    event.preventDefault();
    var file = this.inputFile.current.files[0];

    if (file === undefined) {
      ReactGA.event({
        category: 'Editing',
        action: 'File is undefined'
      });
      alert("Please select a CSV file.");
      return;
    }

    const darkColor = this.state.darkColor;
    const lightColor = this.state.lightColor;
    const addLabelToCode = this.state.addLabelToCode;

    var canvas = this.refs.drawCanvas;

    Papa.parse(file, {
      header: true,
      download: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      fields: ["data", "label"],
      transformHeader: header => header.toLowerCase().replace(/\W/g, "_"),
      error: function(err, file, inputElem, reason) {
        alert(reason);
      },
      complete: function(rows) {
        console.debug(rows);
        ReactGA.event({
          category: 'Conversion',
          action: 'Rows uploaded',
          value: rows.length
        });
        var zip = new JSZip();
        var images = zip.folder("images");
        var counter = 0;
        rows.data.every(function(row, index) {
          if (typeof row.data === "undefined" || typeof row.label === "undefined") {
            alert("Expected data and label as column names in the CSV file.");
            return false;
          }
          QRCode.toDataURL(
            canvas,
            row.data,
            {
              errorCorrectionLevel: "H",
              color: {
                dark: darkColor,
                light: lightColor
              }
            },
            function(err) {
              if (err) {
                throw err;
              }

              // write the text on the canvas
              if (addLabelToCode) {
                var context = canvas.getContext('2d');
                context.font = "12px Arial"
                context.fillStyle = darkColor;
                context.textAlign = "center";
                context.fillText(row.label, canvas.width/2, canvas.height - 4);
              }

              var dataUrl = canvas.toDataURL();
              const pngData = dataUrl.replace("data:image/png;base64,", "");
              images.file(row.label + ".png", pngData, { base64: true });
              counter += 1;
            }
          );
          return true;
        });
        ReactGA.event({
          category: 'Conversion',
          action: 'Codes generated',
          value: counter
        });
        zip.generateAsync({ type: "blob" }).then(function(content) {
          FileSaver.saveAs(
            content,
            file.name.substring(0, file.name.length - 4) + "-codes.zip"
          );
        });
      }
    });
  };

  render() {
    return (
      <Container>
        <ValueProps />
        <hr className="mb-4"></hr>
        <h5 className="mb-3">Settings</h5>
        <Form onSubmit={this.handleFileSelect}>
        <Row className="justify-content-md-center">
          <Col xs lg="2">
              Preview
              <canvas id="previewCanvas" ref="previewCanvas" width={164} height={164} />
              <canvas id="drawCanvas" ref="drawCanvas" width={200} height={200} />
          </Col>
          <Col style={{textAlign: 'left' }}>
            <QRCColorPicker 
              id="dark-color-picker"
              label="Select color for code:  "
              onChange={this.handleDarkColorChange} /> <br/>
            <QRCColorPicker
              id="light-color-picker"
              label="Select background color:  "
              onChange={this.handleLightColorChange}/><br/>
          <FormCheck>
            <FormCheck.Input type="checkbox" checked={this.state.addLabelToCode} onChange={this.handleAddLabelToCodeChange}/>
            <FormCheck.Label>Add label to QRCode image</FormCheck.Label>
          </FormCheck>
          </Col>
          <Col>
          <Form.Group>
            <Form.File id="custom-file" label="Select your CSV file" ref={this.inputFile} accept=".csv" custom />
            <Form.Label>
              The CSV file should only have <b>2 columns</b>. The headers of the
              column are <b>data</b> and <b>label</b>. The number
              of rows does not matter.
            </Form.Label>
          </Form.Group>
          </Col>
          <Col md={{ span: 6, offset: 4 }}>
          <Form.Row>
            <Form.Group>
              <Button variant="primary" type="submit" block>
                Download codes
              </Button>
            </Form.Group>
          </Form.Row>
          </Col>
        </Row>
        </Form>
        <Footer />
      </Container>
    );
  }
}

export default Home;
