import * as React from "react";

export class Footer extends React.Component {
  render() {
    return (
      <footer>
        <p className="mb-1">© 2020 - QRCode Hero  -- <a href="mailto:feedback@qrcodehero.com"><span/> Questions</a></p>
      </footer>
    );
  }
}




  