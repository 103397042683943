import * as React from "react";

import { Nav, Navbar } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQrcode
} from "@fortawesome/free-solid-svg-icons";

export class Header extends React.Component {
  render() {
    return (
      <Navbar expand="lg">
        <Navbar.Brand><FontAwesomeIcon icon={faQrcode} />  QRCode Hero</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}
