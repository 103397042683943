import React from 'react';
import { Header } from "./components/Header";

import Routes from "./routes";
import './App.css';

function App() {
  return (
    <div className="App container">
      <Header />
      <Routes />
    </div>
  );
}

export default App;
